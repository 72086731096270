import { useApi } from 'services';

export const useGetPeople = (idUser, idRouter) => useApi(`/people/${idRouter}`, 'GET', { 'x-user-id': idUser });

export const useCreatePerson = (idUser, idRouter, data) =>
  useApi(`/people/${idRouter}`, 'POST', { 'x-user-id': idUser }, { ...data });

export const useGetPerson = (idUser, idRouter, idPerson) =>
  useApi(`/people/${idRouter}/${idPerson}`, 'GET', { 'x-user-id': idUser });

export const useUpdatePerson = (idUser, idRouter, idPerson, data) =>
  useApi(`/people/${idRouter}/${idPerson}`, 'PUT', { 'x-user-id': idUser }, { ...data });

export const useDeletePerson = (idUser, idRouter, idPerson, canDeleteRoutines) =>
  useApi(`/people/${idRouter}/${idPerson}`, 'DELETE', { 'x-user-id': idUser }, { canDeleteRoutines });

export const useBlockPerson = (idUser, idRouter, idPerson) =>
  useApi(`/people/block/${idRouter}/${idPerson}`, 'PUT', { 'x-user-id': idUser }, {});

export const useAllowPerson = (idUser, idRouter, idPerson) =>
  useApi(`/people/allow/${idRouter}/${idPerson}`, 'PUT', { 'x-user-id': idUser }, {});

export const useGetPersonTimeUsageDay = (idUser, idRouter, idPerson, day) =>
  useApi(`/people/time-usage/day/${idRouter}/${idPerson}?day=${day}`, 'GET', { 'x-user-id': idUser });

export const useGetPersonTimeUsageWeek = (idUser, idRouter, idPerson, week, year) =>
  useApi(`/people/time-usage/week/${idRouter}/${idPerson}?week=${week}&year=${year}`, 'GET', { 'x-user-id': idUser });

export const useGetPersonTimeUsageMonth = (idUser, idRouter, idPerson, month, year) =>
  useApi(`/people/time-usage/month/${idRouter}/${idPerson}?month=${month}&year=${year}`, 'GET', { 'x-user-id': idUser });

export const useGetPersonTimeUsageYear = (idUser, idRouter, idPerson, year) =>
  useApi(`/people/time-usage/year/${idRouter}/${idPerson}?year=${year}`, 'GET', { 'x-user-id': idUser });