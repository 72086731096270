/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'ui-component/extended/AnimateButton';

import Logo from 'assets/images/logo.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AuthContext from 'contexts/AuthContext';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useLogin } from 'services/UserService';

const Login = ({ ...others }) => {
    const formikRef = useRef(null)
    const { updateAuthContext } = useContext(AuthContext);

    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [snackAlert, setSnackAlert] = useState({
        open: false,
        message: '',
        level: 'success'
    });

    const {
        data: useLoginData,
        loading: useLoginLoading,
        error: useLoginError,
        status: useLoginStatus,
        request: useLoginRequest,
    } = useLogin(formikRef.current?.values.email, formikRef.current?.values.password);

    const handleSubmit = (values) => {
        useLoginRequest({ email: values.email, password: values.password });
    };

    useEffect(() => {
        if (useLoginStatus !== 200) return;

        const handleLogin = async () => {
            const { token, refreshToken, user, allRouters, currentRouter, isAdmin } = useLoginData;
            if (!user.isAdmin) return setSnackAlert({
                open: true,
                message: 'Usuário sem permissão!',
                level: 'error'
            })

            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('idUser', user.id.toString());
            localStorage.setItem('name', user.name);
            localStorage.setItem('currentRouter', JSON.stringify(currentRouter));
            localStorage.setItem('routers', JSON.stringify(allRouters));

            await updateAuthContext();
            window.location.reload();
        };

        handleLogin();
    }, [useLoginStatus]);

    useEffect(() => {
        if (useLoginStatus === 400 && useLoginError?.validationError === 'Usuário não encontrado') {
            return setSnackAlert({
                open: true,
                message: 'Usuário não cadastrado!',
                level: 'error'
            })
        }

        if (useLoginStatus === 400 && useLoginError?.validationError === 'Usuário e/ou senha incorreto(s)') {
            return setSnackAlert({
                open: true,
                message: 'Usuário e/ou senha incorreto(s)',
                level: 'error'
            })
        }
    }, [useLoginStatus]);


    return (
        <Box sx={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            justifyItems: 'center',
            alignContent: 'center',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            paddingLeft: '20px',
            paddingRight: '20px'
        }}>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackAlert.open} autoHideDuration={3000} onClose={() => setSnackAlert({ ...snackAlert, open: false })}>
                <MuiAlert variant="filled" onClose={() => setSnackAlert({ ...snackAlert, open: false })} severity={snackAlert.level} sx={{ width: '100%' }}>
                    {snackAlert.message || ''}
                </MuiAlert>
            </Snackbar>

            <Box style={{ marginBottom: 20 }}>
                <img src={Logo} alt="Berry" width="100" />
            </Box>

            <Formik
                innerRef={formikRef}
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('E-mail inválido').max(255).required('Campo obrigatório'),
                    password: Yup.string().max(255).required('Campo obrigatório')
                })}
                onSubmit={(values) => handleSubmit(values)}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Email"
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Senha</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Senha"
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    {useLoginLoading ? 'AGUARDE...' : 'ENTRAR'}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Login;
