/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react';
import Container from "components/Container";

import {
    Box,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    Container as MaterialContainer,
    Typography,
    MenuItem,
    Select,
    InputLabel,
    Switch,
    Grid,
    FormGroup,
    FormLabel,
    Radio
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetUser } from 'services/UserService';
import { useParams } from "react-router-dom";
import { DeviceTypesEnum } from 'codewall-common/enums';
import moment from 'moment';
import { useAllowDevice, useBlockDevice, useDeleteDevice, useUpdateDevice } from 'services/DeviceService';
import LoaderContext from 'contexts/LoaderContext';
import AlertDialog from 'components/AlertDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { IconArrowLeft as IconArrowBack } from '@tabler/icons';

export default function UserDevice() {
    const { updateIsLoadingStatus } = useContext(LoaderContext);
    const navigate = useNavigate();

    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [snackAlert, setSnackAlert] = useState({
        open: false,
        message: '',
        level: 'success'
    });

    const [user, setUser] = useState({});
    const [device, setDevice] = useState({});
    const [router, setRouter] = useState({});
    const deviceTypes = DeviceTypesEnum.enums.map((res) => res.value);

    const [currentPage, setCurrentPage] = useState(1);
    const [value, setValue] = useState(0);
    let { idUser, idDevice } = useParams();

    const handleChange = (key, value) => {
        setDevice({ ...device, [key]: value });
    };

    const handleChangeLists = (key, value, checked) => {
        let values = device[key] || [];

        if (!checked) values = values.filter(v => v.id !== value.id);
        if (checked) values.push(value);

        setDevice({ ...device, [key]: values });
    };

    const {
        data: useGetUserData,
        loading: useGetUserLoading,
        error: useGetUserError,
        status: useGetUserStatus,
        request: useGetUserRequest,
    } = useGetUser(idUser);

    const {
        data: useUpdateDeviceData,
        loading: useUpdateDeviceLoading,
        status: useUpdateDeviceStatus,
        request: useUpdateDeviceRequest,
        source: useUpdateDeviceSource,
    } = useUpdateDevice(idUser, router?.id, device?.macAddress, device);

    const {
        data: useBlockDeviceData,
        loading: useBlockDeviceLoading,
        status: useBlockDeviceStatus,
        request: useBlockDeviceRequest,
        source: useBlockDeviceSource,
    } = useBlockDevice(idUser, router?.id, device?.macAddress);

    const {
        data: useAllowDeviceData,
        loading: useAllowDeviceLoading,
        status: useAllowDeviceStatus,
        request: useAllowDeviceRequest,
        source: useAllowDeviceSource,
    } = useAllowDevice(idUser, router?.id, device?.macAddress);

    const {
        data: useDeleteDeviceData,
        loading: useDeleteDeviceLoading,
        status: useDeleteDeviceStatus,
        request: useDeleteDeviceRequest,
        source: useDeleteDeviceSource,
    } = useDeleteDevice(idUser, router?.id, device?.macAddress, true);

    useEffect(() => {
        useGetUserRequest(null, { page: currentPage })
    }, [currentPage])

    useEffect(() => {
        if (useGetUserStatus !== 200) return;
        setUser(useGetUserData);
    }, [useGetUserStatus]);

    useEffect(() => {
        if (user?.routers) setRouter(user.routers[0])
    }, [user?.routers])

    useEffect(() => {
        const device = router?.devices?.find(v => v.id === +idDevice);
        setDevice(device);
    }, [router]);


    useEffect(() => {
        if (!useDeleteDeviceStatus) return;
        if (useDeleteDeviceStatus === 200 || useDeleteDeviceStatus === 204) {
            setSnackAlert({
                open: true,
                message: 'Dispositívo removido com sucesso!',
                level: 'success'
            });
            return navigate(-1)
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao remover o dispositivo!',
            level: 'error'
        })
    }, [useDeleteDeviceStatus]);

    useEffect(() => {
        if (!useUpdateDeviceStatus) return;
        if (useUpdateDeviceStatus === 200 || useUpdateDeviceStatus === 204) {
            return setSnackAlert({
                open: true,
                message: 'Dispositívo atualizado com sucesso!',
                level: 'success'
            });
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao atualizar o dispositivo!',
            level: 'error'
        })
    }, [useUpdateDeviceStatus]);

    useEffect(() => {
        if (!useAllowDeviceStatus) return;
        if (useAllowDeviceStatus === 200 || useAllowDeviceStatus === 204) {
            return setSnackAlert({
                open: true,
                message: 'Dispositívo desbloqueado com sucesso!',
                level: 'success'
            });
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao desbloquear o dispositivo!',
            level: 'error'
        })
    }, [useAllowDeviceStatus]);

    useEffect(() => {
        if (!useBlockDeviceStatus) return;
        if (useBlockDeviceStatus === 200 || useBlockDeviceStatus === 204) {
            return setSnackAlert({
                open: true,
                message: 'Dispositívo bloqueado com sucesso!',
                level: 'success'
            });
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao bloquer o dispositivo!',
            level: 'error'
        })
    }, [useBlockDeviceStatus]);

    useEffect(() => {
        updateIsLoadingStatus(useUpdateDeviceLoading || useGetUserLoading || useBlockDeviceLoading || useAllowDeviceLoading || useDeleteDeviceLoading)
    }, [useUpdateDeviceLoading, useGetUserLoading, useBlockDeviceLoading, useAllowDeviceLoading, useDeleteDeviceLoading])

    return (
        <Container>
            <AlertDialog
                isOpen={alertModalOpen}
                setIsOpen={setAlertModalOpen}
                onConfirm={() => useDeleteDeviceRequest()}
                message={`Tem certeza que deseja apagar este dispositivo?\nTodos as informações relacionadas serão removidas e os dados não poderão ser recuperados.`}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackAlert.open} autoHideDuration={3000} onClose={() => setSnackAlert({ ...snackAlert, open: false })}>
                <MuiAlert variant="filled" onClose={() => setSnackAlert({ ...snackAlert, open: false })} severity={snackAlert.level} sx={{ width: '100%' }}>
                    {snackAlert.message || ''}
                </MuiAlert>
            </Snackbar>

            <Typography variant="h1" component="h3" style={{ marginBottom: 10 }}>
                {user?.name}
            </Typography>
            <Typography variant="h3" component="h4" style={{ marginBottom: 10 }}>
                Dispositivo: {device?.name}
            </Typography>

            <Grid container spacing={2}>
                <Grid xs={12} sm={6} sx={{ padding: '10px' }}>
                    <FormControlLabel
                        control={<Switch
                            checked={device?.isBlocked}
                            color="secondary"
                            margin="normal"
                            name="isBlocked"
                        />}
                        name="isBlocked"
                        label={device?.isBlocked ? 'Desloquear' : 'Bloquear'}
                        onChange={(e) => {
                            handleChange('isBlocked', e?.target?.checked)
                            !e.target.checked ? useAllowDeviceRequest() : useBlockDeviceRequest();
                        }}
                    />
                    <FormControl fullWidth>
                        <TextField
                            label="Nome"
                            color="secondary"
                            margin="normal"
                            focused
                            value={device?.name}
                            fullWidth
                            onChange={(e) => handleChange('name', e?.target?.value || '')}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal" color="secondary" focused>
                        <InputLabel id="device-type-label" color="secondary" focused>Tipo do aparelho</InputLabel>
                        <Select
                            labelId="device-type-label"
                            color="secondary"
                            margin="normal"
                            focused
                            fullWidth
                            name="deviceType"
                            label="Tipo do aparelho"
                            value={`${device?.deviceType}`}
                            onChange={(e) => handleChange('deviceType', e?.target?.value || '')}
                        >
                            {deviceTypes.map(deviceType => <MenuItem color="secondary" value={deviceType?.id}>{deviceType?.name}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal" color="secondary" focused>
                        <FormLabel component="legend">Pessoas</FormLabel>
                        <FormGroup>
                            {router?.people?.map(person => <FormControlLabel
                                control={<Checkbox
                                    checked={!!device?.people?.find(p => p.id === person.id)}
                                    onChange={e => handleChangeLists('people', person, e?.target?.checked)}
                                />}
                                label={person.name}
                            />)}
                        </FormGroup>
                    </FormControl>

                    <FormControl fullWidth margin="normal" color="secondary" focused>
                        <FormLabel component="legend">Cômodos</FormLabel>
                        <FormGroup>
                            {router?.rooms?.map(room => <FormControlLabel
                                control={<Radio
                                    checked={device?.room?.id === room.id}
                                    onChange={e => handleChange('room', room)}
                                />}
                                label={room.name}
                                name="room"
                            />)}
                        </FormGroup>
                    </FormControl>

                    <FormControl fullWidth>
                        <Button
                            variant="contained"
                            disableElevation style={{ fontWeight: 'bolder' }}
                            sx={{ marginTop: '40px' }}
                            onClick={() => useUpdateDeviceRequest(device)}
                        >
                            SALVAR
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            disableElevation style={{ fontWeight: 'bolder' }}
                            sx={{ marginTop: '10px' }}
                            onClick={() => setAlertModalOpen(true)}
                        >
                            REMOVER
                        </Button>
                    </FormControl>

                    <Typography variant="h3" component="h4" style={{ margin: '50px 0' }}>
                        <Link style={{ display: 'flex' }} to={`/users/${user?.id}?tabIndex=2`}>
                            <IconArrowBack /> Voltar
                        </Link>
                    </Typography>
                </Grid>

                <Grid xs={12} sm={6} sx={{ padding: '10px' }}>
                    <Box justifyContent="space-between" alignItems={'center'} mt={'10px'}>
                        <Typography variant="h4" component="h4">Endereço de IP</Typography>
                        <Typography>{device?.ip}</Typography>
                    </Box>
                    <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Endereço MAC</Typography>
                        <Typography>{device?.macAddress || 'Não disponível'}</Typography>
                    </Box>

                    <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Marca</Typography>
                        <Typography>{device?.manufacturer || 'Não definido'}</Typography>
                    </Box>
                    <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Modelo</Typography>
                        <Typography>{device?.model || 'Não definido'}</Typography>
                    </Box>

                    <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Visto pela última vez</Typography>
                        <Typography>
                            {device?.lastTimeOnlineAt
                                ? moment(device?.lastTimeOnlineAt).format('DD/MM/YYYY, HH:mm')
                                : 'Não disponível'}
                        </Typography>
                    </Box>
                </Grid>

            </Grid>
        </Container>
    );
}