/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from 'react';
import { Typography, Button, Box, Container as MaterialContainer, Tabs, Tab } from '@mui/material';
import Container from "components/Container";
import { useGetUser } from 'services/UserService';
import { useParams } from "react-router-dom";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import UserDetail from "./UserDetail";
import UserRoutines from "./UserRoutines";
import UserDevices from "./UserDevices";
import UserPersons from "./UserPersons";
import UserNetwork from "./UserNetwork";
import UserNetworkSnooper from "./UserNetworkSnooper";
import LoaderContext from 'contexts/LoaderContext';
import UserRouter from './UserRouter';

export default function User() {
    const { updateIsLoadingStatus } = useContext(LoaderContext);

    const [user, setUser] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = useState(+searchParams.get('tabIndex') || 0);
    const history = useNavigate();
    const location = useLocation();
    const { idUser } = useParams();

    const handleChange = (event, newValue) => {
        setSearchParams({ tabIndex: newValue });
        setValue(newValue);
    };

    const {
        data: useGetUserData,
        loading: useGetUserLoading,
        error: useGetUserError,
        status: useGetUserStatus,
        request: useGetUserRequest,
    } = useGetUser(idUser);

    useEffect(() => {
        if (+value) setSearchParams({ tabIndex: value });
    }, [])

    useEffect(() => {
        useGetUserRequest(null, { page: currentPage })
    }, [currentPage])

    useEffect(() => {
        setValue(+searchParams.get('tabIndex') || 0)
    }, [location.search])

    useEffect(() => {
        if (useGetUserStatus !== 200) return;
        setUser(useGetUserData);
    }, [useGetUserStatus]);

    useEffect(() => {
        updateIsLoadingStatus(useGetUserLoading)
    }, [useGetUserLoading])

    return (
        <Container>
            <Typography variant="h1" component="h3" style={{ marginBottom: 10 }}>
                {user?.name}
            </Typography>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        <Tab label="Dados Pessoais" />
                        <Tab label="Roteador" />
                        <Tab label="Dispositivos" />
                        <Tab label="Pessoas" />
                        <Tab label="Rotinas" />
                        <Tab label="Wi-fi" />
                        <Tab label="Canais Wi-fi" />
                    </Tabs>
                </Box>

                <UserDetail user={user} value={value} index={0} />

                <UserRouter user={user} value={value} index={1} />

                <UserDevices user={user} value={value} index={2} />

                <UserPersons user={user} value={value} index={3} />

                <UserRoutines user={user} value={value} index={4} />

                <UserNetwork user={user} value={value} index={5} />

                <UserNetworkSnooper user={user} value={value} index={6} />
            </Box>
        </Container>
    );
}