const Enum = require("enum");
Enum.register();

const CommandSpoolCommandTypeEnum = new Enum({
  INITIAL_SETUP: { id: 0 },
  BLOCK_DEVICE: { id: 1 },
  UNBLOCK_DEVICE: { id: 2 },
  WIFI_SETUP: { id: 3 }
});

module.exports = CommandSpoolCommandTypeEnum;
