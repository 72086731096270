/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from "react";
import TableContainer from "@mui/material/TableContainer";
import {
  Button,
  Container as MaterialContainer,
  Grid,
} from "@mui/material";
import Container from "components/Container";
import { useGetUsersWithFilterPost } from "services/UserService";
import { Link } from "react-router-dom";
import { IconEdit } from "@tabler/icons";
import LoaderContext from "contexts/LoaderContext";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  TextField,
} from "../../node_modules/@mui/material/index";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { DataGrid } from "@mui/x-data-grid";

export default function Users() {
  const { updateIsLoadingStatus } = useContext(LoaderContext);

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userType, setUserType] = useState(3);
  const [filterText, setFilterText] = useState("");
  const [checkedADM, setCheckedADM] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [wifiName, setWifiName] = useState("");
  const [routerStatus, setRouterStatus] = useState(true);
  const [mk, setmk] = useState(true);
  
  const filterUsers = users.filter((user) => {
    return user;
  });

  const {
    data: useGetUsersWithFilterData,
    loading: useGetUsersWithFilterLoading,
    status: useGetUsersWithFilterStatus,
    request: useGetUsersWithFilterRequest,
  } = useGetUsersWithFilterPost(userType, filterText, routerStatus, checkedADM, serialNumber, wifiName);

  useEffect(() => {
    useGetUsersWithFilterRequest(null, { page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    if (useGetUsersWithFilterStatus !== 200) return;
    setUsers([...users, ...useGetUsersWithFilterData]);
  }, [useGetUsersWithFilterStatus]);

  useEffect(() => {
    updateIsLoadingStatus(useGetUsersWithFilterLoading);
  }, [useGetUsersWithFilterLoading]);


  const handleClearFilters = () => {
    setRouterStatus(null);
    setmk(null);
    setFilterText("");
    setCheckedADM(false);
    setSerialNumber("");
    setWifiName("");
    setUsers([]);
    setCurrentPage(1);
    setUserType(1)
  };

  useEffect(() => {
      setUsers([]);
      setCurrentPage(1);
      useGetUsersWithFilterRequest();
  }, [userType, filterText, checkedADM, serialNumber, wifiName, routerStatus, mk]);

  const setMkTrueClearFilters = () => {
    const newMk = mk === false || mk === null ? true : null;
    setmk(newMk);
    setWifiName("");
    setSerialNumber("");
    setRouterStatus(newMk === true ? true : null);
    setUserType(newMk === true ? 3 : 1);
  };
  
  const setMkFalseClearFilters = () => {
    const newMk = mk === true || mk === null ? false : null;
    setmk(newMk);
    setWifiName("");
    setSerialNumber("");
    setRouterStatus(newMk === false ? null : null);
    setUserType(newMk === false ? 2 : 1);
  };
  
  const totalUsers = filterUsers.length;
  const usersPerPage = 19;
  const totalPages = Math.ceil(totalUsers / usersPerPage);
  const isLastPage = currentPage >= totalPages;

  return (
    <Container>
      <Grid container sx={{ marginX: 4 }}>
      <Grid sx={{ marginX: 2 }}>
      <InputLabel shrink htmlFor="bootstrap-input">
        Dados do usuário:
      </InputLabel>
      <TextField
        id="fullWidth"
        sx={{ width: 320 }}
        placeholder="ID, Nome, Email, Telefone, CPF"
        focused
        value={filterText}
        onChange={(e) => setFilterText(e.target.value != null && e.target.value.length > 0 ? e.target.value : "")}
      />
      </Grid>

        {mk && (
          <Grid sx={{ marginX: 2, mt: { xs: 1, sm: 0 } }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Dados da MK:
            </InputLabel>
            <TextField
              sx={{ width: 320 }}
              placeholder="Numero de Serie"
              value={serialNumber}
              focused
              onChange={(e) => setSerialNumber(e.target.value != null && e.target.value.length > 0 ? e.target.value : "")}
            />
            <Grid my={4}>
              <TextField
                id="fullWidth"
                sx={{ width: 320 }}
                placeholder="Nome do Wi-Fi"
                value={wifiName}
                focused
                onChange={(e) => setWifiName(e.target.value != null && e.target.value.length > 0 ? e.target.value : "")}
              />
            </Grid>
          </Grid>
        )}

        <Grid sx={{ marginX: 2 }}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Status de vinculo com MK
          </InputLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mk === false}
                  onChange={() => setMkFalseClearFilters()}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<TaskAltIcon />}
                />
              }
              label="Sem Mikrotik"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={mk === true}
                  onChange={() => setMkTrueClearFilters()}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<TaskAltIcon />}
                />
              }
              label="Com Mikrotik"
              labelPlacement="start"
            />
          </FormGroup>
        </Grid>
        {mk && (
          <Grid sx={{ marginX: 5 }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Roteador:
            </InputLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={routerStatus === true}
                    onChange={() =>
                      setRouterStatus(routerStatus === true ? null : true)
                    }
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<TaskAltIcon />}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={routerStatus === false}
                    onChange={() =>
                      setRouterStatus(routerStatus === false ? null : false)
                    }
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<TaskAltIcon />}
                  />
                }
                label="Desativado"
                labelPlacement="start"
              />
            </FormGroup>
          </Grid>
        )}
        <Grid>
          <FormHelperText>Tipo Usuário</FormHelperText>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedADM}
                onChange={(e) => setCheckedADM(e.target.checked)}
              />
            }
            label="Usuario ADM"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <Button variant="contained" onClick={handleClearFilters}>
        Limpar filtro
      </Button>
      <TableContainer
        sx={{
          marginTop: 1,
          marginBottom: 4,
          width: "100%",
          maxWidth: "xl",
        }}
      >
        <DataGrid
          sx={{
            "& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root": {
              color: "#fff",
            },
            "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator--sideRight":
              {
                display: "none",
              },
            "& .MuiDataGrid-columnHeader:last-child": {
              display: "none",
            },
            "& .css-1iyq7zh-MuiDataGrid-columnHeaders": {
              backgroundColor: "#372198",
              color: "#fff",
            },
          }}
          rows={filterUsers.map((user) => ({ ...user, id: user.id }))}
          columns={[
            { field: "id", headerName: "ID", width: 100 },
            { field: "name", headerName: "Nome", width: 300 },
            { field: "email", headerName: "Email", width: 300 },
            { field: "phone", headerName: "Telefone", width: 150 },
            {
              field: "devices",
              headerName: "Dispositivos",
              width: 150,
              align: "center",
            },
            {
              field: "routines",
              headerName: "Rotinas",
              width: 150,
              align: "center",
            },
            { field: "lastTimeOnlineAt", headerName: "Online", width: 200 },
            {
              field: "",
              menubar: false,
              width: 100,
              renderCell: (params) => (
                <Link
                  to={`/users/${params.row.id}?tabIndex=0`}
                  key={params.row.id}
                >
                  <IconEdit />
                </Link>
              ),
            },
          ]}
          pageSize={usersPerPage}
          rowsPerPageOptions={[19]}
          loading={useGetUsersWithFilterLoading}
          pagination
        />
        <MaterialContainer
          style={{
            maxWidth: "xl",
            marginTop: 40,
            marginBottom: 10,
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            style={{ fontWeight: "bolder" }}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={isLastPage}
          >
            CARREGAR MAIS
          </Button>

          
        </MaterialContainer>
      </TableContainer>
    </Container>
    
  );
  
}
