import React, { useEffect, useState, createContext } from 'react';
import { api } from 'services';

const AuthContext = createContext({
    signed: false,
    token: null,
    isLoading: true,
    idUser: null,
    name: null,
    updateAuthContext: () => { },
    deleteAuthContext: () => { },
  });
  
  const AuthContextProvider = ({ children }) => {
    const [idUser, setIdUser] = useState(null);
    const [name, setName] = useState(null);
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      setIsLoading(true);
      updateAuthContext();
    }, []);
  
    const updateAuthContext = async () => {
      const id = localStorage.getItem('idUser');
      const name = localStorage.getItem('name');
      const token = localStorage.getItem('token');
  
      api.defaults.headers.Authorization = `Bearer ${token}`;
  
      setToken(token);
      setIdUser(id);
      setName(name);
  
      setTimeout(() => setIsLoading(false), 500);
    };
  
    const deleteAuthContext = async () => {
      localStorage.removeItem('idUser');
      localStorage.removeItem('name');
      localStorage.removeItem('token');
  
      localStorage.removeItem('devices');
      localStorage.removeItem('people');
      localStorage.removeItem('routines');
  
      setIdUser(null);
      setName(null);
      setToken(null);
    
      api.defaults.headers.Authorization = null;
  
      setTimeout(() => setIsLoading(false), 500);
    };
  
    return (
      <AuthContext.Provider
        value={{
          signed: !!token,
          token,
          isLoading,
          idUser,
          name,
          updateAuthContext,
          deleteAuthContext,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
  
  export { AuthContext, AuthContextProvider };
  

export default AuthContext;
