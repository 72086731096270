import { useApi } from 'services';

export const useGetRoutines = (idUser, idRouter) => useApi(`/routine/${idRouter}`, 'GET', { 'x-user-id': idUser });

export const useGetRoutineById = (idUser, idRouter, idRoutine) =>
  useApi(`/routine/${idRouter}/${idRoutine}`, 'GET', { 'x-user-id': idUser });

export const useUpdateRoutine = (idUser, idRouter, idRoutine, routine) =>
  useApi(`/routine/${idRouter}/${idRoutine}`, 'PUT', { 'x-user-id': idUser }, routine);

export const useDeleteRoutine = (idUser, idRouter, idRoutine) =>
  useApi(`/routine/${idRouter}/${idRoutine}`, 'DELETE', { 'x-user-id': idUser }, {});

export const useCreateRoutine = (idUser, idRouter, routine) =>
  useApi(`/routine/${idRouter}`, 'POST', { 'x-user-id': idUser }, routine);
