// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Customization from '../Customization';

// assets
import { useEffect, useState } from "react";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => {
    const drawerWidth = 20;
    return {
      ...theme.typography.mainContent,
      backgroundColor: theme.colors?.gray200,
      ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up("md")]: {
          marginLeft: -(drawerWidth - 20),
          width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down("md")]: {
          marginLeft: "20px",
          width: `calc(100% - ${drawerWidth}px)`,
          padding: "16px 8px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "10px",
          width: `calc(100% - ${drawerWidth}px)`,
          padding: "16px 8px",
          marginRight: "10px",
        },
      }),
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down("md")]: {
          marginLeft: "20px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "10px",
        },
      }),
    };
  }
);

const MainLayout = ({ children }) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const [leftDrawerOpened, setLeftDrawerOpened] = useState(!matchDownMd);

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: leftDrawerOpened
          ? { xs: "100%", sm: "100%", md: "53rem", lg: "100%" }
          : "100%",
      }}
    >
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar>
          <Header
            handleLeftDrawerToggle={() =>
              setLeftDrawerOpened(!leftDrawerOpened)
            }
          />
        </Toolbar>
      </AppBar>
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={() => setLeftDrawerOpened(!leftDrawerOpened)}
      />

      <Main theme={theme} open={leftDrawerOpened} style={{ paddingTop: 0 }}>
        {children}
      </Main>
    </Box>
  );
};

export default MainLayout;
