import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

import { IconReport, IconUser } from '@tabler/icons';
import MenuItem from './MenuItem';
import LogoSection from '../LogoSection';

const pages = [{
    id: 'user',
    title: 'Usuários',
    type: 'item',
    url: '/users',
    icon: IconUser,
    breadcrumbs: false
}, {
    id: 'reports',
    title: 'Relatórios',
    type: 'item',
    url: '/reports',
    icon: IconReport,
    breadcrumbs: false
},]

const MenuItems = pages.map((page, index) => (
    <MenuItem key={index} item={page}></MenuItem>
))

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const container = window !== undefined ? () => window.document.body : undefined;
    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: drawerOpen ? 200 : 'auto' }}
            aria-label="menu"
        >
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 200,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                        <LogoSection />
                    </Box>
                </Box>
                <BrowserView>
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        }}
                    >
                        {MenuItems}
                    </PerfectScrollbar>
                </BrowserView>
                <MobileView>
                    <Box sx={{ px: 2 }}>
                        {MenuItems}
                    </Box>
                </MobileView>
            </Drawer>
        </Box>
    );
};

export default Sidebar;
