/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react';
import Container from "components/Container";

import {
    Box,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    Container as MaterialContainer,
    Typography,
    MenuItem,
    Select,
    InputLabel,
    Switch,
    Grid,
    FormGroup,
    FormLabel,
    Radio
} from '@mui/material';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';

export default function UserRouter({ value, index, user }) {
    const [snackAlert, setSnackAlert] = useState({
        open: false,
        message: '',
        level: 'success'
    });

    const [router, setRouter] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (user?.routers) setRouter(user.routers[0])
    }, [user?.routers])

    useEffect(() => {
        if (user?.routers) setRouter(user.routers[0])
    }, [user?.routers])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={snackAlert.open} autoHideDuration={3000} onClose={() => setSnackAlert({ ...snackAlert, open: false })}>
                        <MuiAlert variant="filled" onClose={() => setSnackAlert({ ...snackAlert, open: false })} severity={snackAlert.level} sx={{ width: '100%' }}>
                            {snackAlert.message || ''}
                        </MuiAlert>
                    </Snackbar>

                    <Typography variant="h1" component="h3" style={{ marginBottom: 10 }}>
                        {user?.name}
                    </Typography>
                    <Typography variant="h3" component="h4" style={{ marginBottom: 10 }}>
                        Roteador: {router?.serialNumber}
                    </Typography>

                    <Grid container spacing={0}>
                        <Grid xs={12} sm={6}>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'10px'}>
                                <Typography variant="h4" component="h4">Numero de série</Typography>
                                <Typography>{router?.serialNumber}</Typography>
                            </Box>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">Data de ativação</Typography>
                                <Typography>
                                    {router?.activationDate
                                        ? moment(router?.activationDate).format('DD/MM/YYYY, HH:mm:ss')
                                        : ''}
                                </Typography>
                            </Box>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">Versão de software atual</Typography>
                                <Typography>{router?.currentFirmwareVersion}</Typography>
                            </Box>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">Versão de software de fábrica</Typography>
                                <Typography>{router?.factoryFirmwareVersion}</Typography>
                            </Box>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">Versão de software disponível para atualização</Typography>
                                <Typography>{router?.upgradableFirmwareVersion}</Typography>
                            </Box>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">Modelo</Typography>
                                <Typography>{router?.model}</Typography>
                            </Box>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">Tipo de Firmware</Typography>
                                <Typography>{router?.firmwareType}</Typography>
                            </Box>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">IP VPN</Typography>
                                <Typography>{router?.vpnIpCache}</Typography>
                            </Box>
                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">Usuário</Typography>
                                <Typography>{router?.name}</Typography>
                            </Box>

                            <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                                <Typography variant="h4" component="h4">Conectado à rede</Typography>
                                <Typography>{!router?.isOnline ? 'Não' : 'Sim'}</Typography>
                            </Box>

                            <FormControl
                                color="secondary"
                                margin="normal"
                                focused
                                variant="contained"
                            >
                                <Typography variant="h4" component="h4">Senha</Typography>
                                <Input
                                    autoComplete='off'
                                    name="password"
                                    id="password"
                                    label="Senha da rede"
                                    color="secondary"
                                    margin="normal"
                                    focused
                                    fullWidth
                                    value={router?.password}
                                    disabled
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6} sx={{ padding: '10px' }}>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    )
}