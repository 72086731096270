const Enum = require("enum");
Enum.register();

const UserFilterEnum = new Enum({
  ALL: {
    id: 1,
    //I18N
    name: "Todos",
  },
  NEW: {
    id: 2,
    //I18N
    name: "Novos",
  },
  WITH_ROUTER: {
    id: 3,
    //I18N
    name: "Com roteador",
  },
  ADMINISTRATORS: {
    id: 4,
    //I18N
    name: "Administradores",
  },
});

module.exports = UserFilterEnum;
