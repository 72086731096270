import React, { useState, createContext } from 'react';

const LoaderContext = createContext({
  isLoading: false,
  updateIsLoadingStatus: (isLoading) => { },
});

export const LoaderContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateIsLoadingStatus = async (isLoading = false) => {
    setIsLoading(isLoading)
  };

  return (
    <LoaderContext.Provider
      value={{
        isLoading,
        updateIsLoadingStatus
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
