import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ message, onConfirm, isOpen, setIsOpen }) {
    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"ATENÇÃO!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={() => setIsOpen(false)}>CANCELAR</Button>
                <Button onClick={() => {
                    setIsOpen(false);
                    onConfirm();
                }} autoFocus>
                    CONFIRMAR
                </Button>
            </DialogActions>
        </Dialog >
    );
}