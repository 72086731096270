const Enum = require("enum");
Enum.register();

const DomainsEnum = new Enum({
  FACEBOOK: {
    id: 1,
    //I18N
    alias: "Facebook",
    domains: '.facebook.com'
  },
  YOUTUBE: {
    id: 2,
    //I18N
    alias: "YouTube",
    domains: '.youtube.com'
  },
  TIKTOK: {
    id: 3,
    //I18N
    alias: "TikTok",
    domains: '.tiktok.com'
  },
});

module.exports = DomainsEnum;
