const Enum = require("enum");
Enum.register();

const RouterStatusEnum = new Enum({
  NOT_CONFIGURED: {
    id: 0,
    //I18N
    name: "Não Configurado",
  },
  CONFIGURED: {
    id: 1,
    //I18N
    name: "Configurado",
  },
});

module.exports = RouterStatusEnum;
