import { useContext, useState } from 'react';
import axios from 'axios';
import AuthContext from 'contexts/AuthContext';

const { REACT_APP_BASE_URL, REACT_APP_DEBUG_REQUESTS } = process.env;
const debugRequests = REACT_APP_DEBUG_REQUESTS;

export const api = axios.create({
    baseURL: REACT_APP_BASE_URL,
});

export const useApi = (url, method, headersData, requestData, syncRequest = false, timeout) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const { deleteAuthContext } = useContext(AuthContext);

    async function request(data, query, headers) {
        setLoading(true);
        setData(null);
        setIsSuccess(false);
        setError(null);
        setStatus(null);

        const optionsLog = {
            method,
            url,
            data: data || requestData,
            params: query
        };

        try {
            const options = {
                ...optionsLog,
                headers: { 'Content-Type': 'application/json', ...headersData, ...(headers || {}) },
                cancelToken: source.token,
                timeout: timeout || 1200000,
            };

            if (debugRequests) console.log('Request started.', REACT_APP_BASE_URL, optionsLog);
            const { data, status } = await api.request(options);

            if (syncRequest) return { data, status };

            setData(data);
            setIsSuccess(true);
            setStatus(status);

            if (debugRequests) console.log('Request finished.', REACT_APP_BASE_URL, optionsLog, { data, status });
        } catch (error) {
            if (axios.isCancel(error)) {
                if (debugRequests) console.log('Request canceled.', REACT_APP_BASE_URL, optionsLog);
                return;
            }

            if (debugRequests) {
                console.error(
                    'Request error.',
                    REACT_APP_BASE_URL,
                    optionsLog,
                    error?.response?.data || error,
                    error?.response?.status,
                );
            }

            // if (error?.response?.status === 401 || error?.response?.status === 403) {
            //     await deleteAuthContext();
            // }

            if (syncRequest) throw error;

            setIsSuccess(false);
            setError(error?.response?.data || error);
            setStatus(error?.response?.status);
        } finally {
            setLoading(false);
        }
    }

    return {
        request,
        data,
        loading,
        error,
        isSuccess,
        status,
        source,
    };
};
