import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

export const PhoneMaskInput = forwardRef(function TextMaskCustom(props, ref, a) {
    const { onChange, mask, ...other } = props;
    return (
        <IMaskInput
            label="Telefone"
            color="secondary"
            margin="normal"
            mask="(00) 00000-0000"
            focused
            fullWidth
            {...other}
            inputRef={ref}
            onAccept={(value) => {
                onChange({ target: { name: props.name, value } })
            }}
            overwrite
        />
    );
});

export const CpfMaskInput = forwardRef(function TextMaskCustom(props, ref, a) {
    const { onChange, mask, ...other } = props;
    return (
        <IMaskInput
            label="Telefone"
            color="secondary"
            margin="normal"
            mask="000.000.000-00"
            focused
            fullWidth
            {...other}
            inputRef={ref}
            onAccept={(value) => {
                onChange({ target: { name: props.name, value } })
            }}
            overwrite
        />
    );
});