const Enum = require("enum");
Enum.register();

const PeopleStatusEnum = new Enum({
  INACTIVE: {
    id: 0,
    //I18N
    name: "Inativo",
  },
  ACTIVE: {
    id: 1,
    //I18N
    name: "Ativo",
  },
});

module.exports = PeopleStatusEnum;
