/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Button,
  Box,
  Container as MaterialContainer,
} from "@mui/material";
import moment from "moment";
import Container from "components/Container";
import { useGetUsers } from "services/UserService";
import { Link } from "react-router-dom";
import { IconEdit } from "@tabler/icons";
import LoaderContext from "contexts/LoaderContext";
import { useRestoreDevice } from "services/DeviceService";
import {
  Navigate,
  useNavigate,
} from "../../node_modules/react-router-dom/dist/index";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.colors.primaryMain,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UserDetail({ value, index, user }) {
  const [router, setRouter] = useState({});
  const { updateIsLoadingStatus } = useContext(LoaderContext);
  const [currentDevice, setCurrentDevice] = useState(null);
  const navigate = useNavigate();
  const [snackAlert, setSnackAlert] = useState({
    open: false,
    message: "",
    level: "success",
  });

  const {
    data: useRestoreDeviceData,
    loading: useRestoreDeviceLoading,
    status: useRestoreDeviceStatus,
    request: useRestoreDeviceRequest,
    source: useRestoreDeviceSource,
  } = useRestoreDevice(user?.id, router?.id, currentDevice?.macAddress);

  useEffect(() => {
    updateIsLoadingStatus(useRestoreDeviceLoading);
  }, [useRestoreDeviceLoading]);

  useEffect(() => {
    if (user?.routers) setRouter(user.routers[0]);
  }, [user?.routers]);

  useEffect(() => {
    if (currentDevice) useRestoreDeviceRequest();
  }, [currentDevice]);

  useEffect(() => {
    if (!useRestoreDeviceStatus) return;
    if (useRestoreDeviceStatus === 200 || useRestoreDeviceStatus === 204) {
      setSnackAlert({
        open: true,
        message: "Dispositívo restaurado com sucesso!",
        level: "success",
      });

      return navigate(`/users/${user?.id}/device/${currentDevice?.id}`);
    }

    setSnackAlert({
      open: true,
      message: "Falha ao restaurar o dispositivo!",
      level: "error",
    });
    setCurrentDevice(null);
  }, [useRestoreDeviceStatus]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackAlert.open}
        autoHideDuration={3000}
        onClose={() => setSnackAlert({ ...snackAlert, open: false })}
      >
        <MuiAlert
          variant="filled"
          onClose={() => setSnackAlert({ ...snackAlert, open: false })}
          severity={snackAlert.level}
          sx={{ width: "100%" }}
        >
          {snackAlert.message || ""}
        </MuiAlert>
      </Snackbar>
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">ID</StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">IP/MAC</StyledTableCell>
                  <StyledTableCell align="left">Pessoas</StyledTableCell>
                  <StyledTableCell align="left">Comodos</StyledTableCell>
                  <StyledTableCell align="left">Registrado</StyledTableCell>
                  <StyledTableCell align="left">Bloqueado</StyledTableCell>
                  <StyledTableCell align="left">Online</StyledTableCell>
                  <StyledTableCell align="left">
                    Data de exclusão
                  </StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {router?.devices?.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {row.id}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {row.name}{" "}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {row.ip}
                      <br />
                      {row.macAddress}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {!row.people?.length ? (
                        "-"
                      ) : (
                        <ul style={{ padding: 0, paddingLeft: 10, margin: 0 }}>
                          {row.people.map((people) => (
                            <li>{people.name}</li>
                          ))}
                        </ul>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {row.room?.name || "-"}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {row.registeredAt ? "SIM" : "NÃO"}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {row.isBlocked ? "SIM" : "NÃO"}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {row.lastTimeOnlineAt
                        ? moment(row.lastTimeOnlineAt).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ opacity: row.deletedAt ? 0.4 : 1 }}
                      align="left"
                      sx={{ verticalAlign: "top" }}
                    >
                      {row.deletedAt
                        ? moment(row.lastTimeOnlineAt).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {!row.deletedAt && (
                        <Button
                          variant="text"
                          onClick={() => {
                            if (router.isOnline === true) {
                              navigate(`/users/${user.id}/device/${row.id}`);
                            } else {
                              setSnackAlert({
                                open: true,
                                message: "Mikrotik do usuário desconectada",
                                level: "error",
                              });
                            }
                          }}
                          sx={{ mb: "10px" }}
                        >
                          <IconEdit />
                        </Button>
                      )}
                      {row.deletedAt && (
                        <Button
                          variant="contained"
                          disableElevation
                          style={{ fontWeight: "bolder" }}
                          onClick={() => {
                            if (router.isOnline === true) {
                              const confirmresult = window.confirm(
                                "Deseja restaurar este dispositivo?"
                              );
                              if (confirmresult) setCurrentDevice(row);
                            } else {
                              setSnackAlert({
                                open: true,
                                message: "Mikrotik do usuário desconectada",
                                level: "error",
                              });
                            }
                          }}
                        >
                          RESTAURAR
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
}
