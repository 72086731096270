/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from 'react';
import { Box, TextField, FormControl, FormControlLabel, Checkbox, Button, Container as MaterialContainer } from '@mui/material';
import AlertDialog from 'components/AlertDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDeleteUser } from 'services/UserService';
import LoaderContext from 'contexts/LoaderContext';
import { useParams, useNavigate } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { useGetNetworks, useUpdateAllNetworks } from 'services/NetworkService';

export default function UserNetwork({ value, index, user }) {
    const { updateIsLoadingStatus } = useContext(LoaderContext);
    const navigate = useNavigate();

    const [router, setRouter] = useState({});
    const [userData, setUserData] = useState(user);
    const [network, setNetwork] = useState({});

    const [showPassword, setShowPassword] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [snackAlert, setSnackAlert] = useState({
        open: false,
        message: '',
        level: 'success'
    });

    const handleChange = (key, value) => {
        setNetwork({ ...network, [key]: value });
    };

    const {
        data: useGetNetworksData,
        loading: useGetNetworksLoading,
        error: useGetNetworksError,
        status: useGetNetworksStatus,
        request: useGetNetworksRequest,
    } = useGetNetworks(user?.id, router?.id);

    const {
        data: useUpdateAllNetworksData,
        loading: useUpdateAllNetworksLoading,
        status: useUpdateAllNetworksStatus,
        request: useUpdateAllNetworksRequest,
        source: useUpdateAllNetworksSource,
    } = useUpdateAllNetworks(user.id, router?.id, network?.name, network?.password);

    useEffect(() => {
        if (value !== index) return;

        setUserData(user);
        if (user?.id && router?.id) useGetNetworksRequest()
    }, [user, router, value, index])

    useEffect(() => {
        if (user?.routers) setRouter(user.routers[0])
    }, [user?.routers])

    useEffect(() => {
        if (!useGetNetworksStatus) return;
        if (useGetNetworksStatus != 200 || !useGetNetworksData?.length) return setSnackAlert({
            open: true,
            message: 'Não foi possível recuperar as informações da rede wi-fi',
            level: 'error'
        });

        setNetwork(useGetNetworksData[0]);
    }, [useGetNetworksStatus]);

    useEffect(() => {
        if (!useUpdateAllNetworksStatus) return;
        if (useUpdateAllNetworksStatus === 200 || useUpdateAllNetworksStatus === 204) {
            return setSnackAlert({
                open: true,
                message: 'Wi-fi atualizada com sucesso!',
                level: 'success'
            });
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao atualizar a Wi-fi!',
            level: 'error'
        })
    }, [useUpdateAllNetworksStatus]);

    const handleUpdateNetwork = () => {
        if (!network?.password || network?.password?.length < 8) return setSnackAlert({
            open: true,
            message: 'A senha deve ter no mínimo 8 dígitos!',
            level: 'error'
        })

        if (network?.password !== network?.passwordConfirm) return setSnackAlert({
            open: true,
            message: 'As senhas informadas são diferentes!',
            level: 'error'
        })

        if (!network?.name) return setSnackAlert({
            open: true,
            message: 'O nome da rede é obrigatório!',
            level: 'error'
        })

        useUpdateAllNetworksRequest(network)
    }

    useEffect(() => {
        updateIsLoadingStatus(useUpdateAllNetworksLoading || useGetNetworksLoading)
    }, [useUpdateAllNetworksLoading, useGetNetworksLoading])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            <AlertDialog
                isOpen={alertModalOpen}
                setIsOpen={setAlertModalOpen}
                // onConfirm={() => useDeleteUserRequest()}
                message={`Tem certeza que deseja apagar este Wi-fi?\nTodos as informações relacionadas serão removidas e os dados não poderão ser recuperados.`}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackAlert.open} autoHideDuration={3000} onClose={() => setSnackAlert({ ...snackAlert, open: false })}>
                <MuiAlert variant="filled" onClose={() => setSnackAlert({ ...snackAlert, open: false })} severity={snackAlert.level} sx={{ width: '100%' }}>
                    {snackAlert.message || ''}
                </MuiAlert>
            </Snackbar>
            {(value === index && network?.id) && (
                <Box sx={{ pt: 3 }}>
                    <FormControl fullWidth sx={{ maxWidth: '400px' }}>
                        <TextField
                            autoComplete="new-password"
                            inputProps={{
                                autoComplete: 'new-password'
                            }}
                            name="wi-fi-name"
                            label="'Nome da rede"
                            color="secondary"
                            margin="normal"
                            focused
                            value={network?.name}
                            fullWidth
                            onChange={(e) => handleChange('name', e?.target?.value || '')}
                        />
                        <FormControl
                            variant="outlined"
                            color="secondary"
                            margin="normal"
                            focused
                        >
                            <InputLabel htmlFor="password">Senha da rede</InputLabel>
                            <OutlinedInput
                                autoComplete='off'
                                name="wi-fi-password"
                                id="password"
                                label="Senha da rede"
                                color="secondary"
                                margin="normal"
                                focused
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>}
                                onChange={(e) => handleChange('password', e?.target?.value || '')}
                            />
                        </FormControl>
                        <FormControl
                            variant="outlined"
                            color="secondary"
                            margin="normal"
                            focused
                        >
                            <InputLabel htmlFor="password-confirmation">Confirmação de senha</InputLabel>
                            <OutlinedInput
                                autoComplete='off'
                                name="wi-fi-password-confirmation"
                                id="password-confirmation"
                                label="Confirmação de senha"
                                color="secondary"
                                margin="normal"
                                focused
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>}
                                onChange={(e) => handleChange('passwordConfirm', e?.target?.value || '')}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <Button
                                variant="contained"
                                disableElevation style={{ fontWeight: 'bolder' }}
                                sx={{ marginTop: '40px' }}
                                onClick={() => handleUpdateNetwork()}
                            >
                                SALVAR
                            </Button>
                            {/* <Button
                                disabled
                                variant="contained"
                                color="error"
                                disableElevation style={{ fontWeight: 'bolder' }}
                                sx={{ marginTop: '10px' }}
                                onClick={() => setAlertModalOpen(true)}
                            >
                                DESABILITAR
                            </Button> */}
                        </FormControl>

                    </FormControl>
                </Box>
            )}
        </div>
    );
}