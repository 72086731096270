const Enum = require("enum");
Enum.register();

const WeekDaysEnum = new Enum({
  SUNDAY: {
    id: 0,
    //I18N
    name: "Domingo",
    //I18N
    initials: "D",
    value: "sun",
  },
  MONDAY: {
    id: 1,
    //I18N
    name: "Segunda",
    //I18N
    initials: "S",
    value: "mon",
  },
  TUESDAY: {
    id: 2,
    //I18N
    name: "Terça",
    //I18N
    initials: "T",
    value: "tue",
  },
  WEDNESDAY: {
    id: 3,
    //I18N
    name: "Quarta",
    //I18N
    initials: "Q",
    value: "wed",
  },
  THURSDAY: {
    id: 4,
    //I18N
    name: "Quinta",
    //I18N
    initials: "Q",
    value: "thu",
  },
  FRIDAY: {
    id: 5,
    //I18N
    name: "Sexta",
    //I18N
    initials: "S",
    value: "fri",
  },
  SATURDAY: {
    id: 6,
    //I18N
    name: "Sábado",
    //I18N
    initials: "S",
    value: "sat",
  },
});

module.exports = WeekDaysEnum;
