import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/pt-br';

import React, { useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import LoaderContext from 'contexts/LoaderContext';

const Loader = () => {
    const { isLoading } = useContext(LoaderContext);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Loader;
