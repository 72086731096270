import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/pt-br';

import React, { useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Routes, Navigate } from 'react-router-dom';
import { LocalizationProvider, ptBR } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ToastContainer } from 'react-toastify';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import AuthContext, { AuthContextProvider } from 'contexts/AuthContext';
import LoaderContext, { LoaderContextProvider } from 'contexts/LoaderContext';
import moment from 'moment';
import theme from 'theme'
import { api } from 'services';

import Login from 'containers/Login'
import UserDevice from 'containers/UserDevice'
import Users from 'containers/Users'
import User from 'containers/User'
import UserPerson from 'containers/UserPerson';
import UserRoutine from 'containers/UserRoutine';
import Loader from 'components/Loader';

const App = () => {
  const token = localStorage.getItem('token');
  api.defaults.headers.Authorization = `Bearer ${token}`;
  const { isLoading } = useContext(LoaderContext);

  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme()}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale="pt-br"
        >
          <CssBaseline />
          <LoaderContextProvider>
            <ToastContainer newestOnTop />
            <Routes>
              {token && <>
                <Route path="/users" element={<Users />} />
                <Route path="/users/:idUser/device/:idDevice" element={<UserDevice />} />
                <Route path="/users/:idUser/person/:idPerson?" element={<UserPerson />} />
                <Route path="/users/:idUser/routine/:idRoutine?" element={<UserRoutine />} />
                <Route path="/users/:idUser" element={<User />} />

                <Route path="*" element={<Navigate to="/users" replace />} />
              </>}
              {!token && <>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </>}
            </Routes>
            <Loader/>
          </LoaderContextProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </AuthContextProvider >
  );
};

export default App;
