const Enum = require("enum");
Enum.register();

const DeviceTypesEnum = new Enum({
  TV: {
    id: 1,
    //I18N
    name: "TV",
    keywords: ["tv", "television"]
  },
  SMARTPHONE: {
    id: 2,
    //I18N
    name: "Celular",
    keywords: ["smartphone", "phone", "generic android", "android", "ios"]
  },
  DESKTOP: {
    id: 3,
    //I18N
    name: "Computador",
    keywords: ["computer", "desktop"]
  },
  LAPTOP: {
    id: 4,
    //I18N
    name: "Notebook",
    keywords: ["laptop"]
  },
  TABLET: {
    id: 5,
    //I18N
    name: "Tablet",
    keywords: ["tablet", "tab", "ipad"]
  },
  GAMING_CONSOLE: {
    id: 6,
    //I18N
    name: "Videogame",
    keywords: ["gaming console", "videogame"]
  },
  SMARTWATCH: {
    id: 7,
    //I18N
    name: "Relógio",
    keywords: ["smartwatch", "wearable", "apple watch", "galaxy watch", "watch"]
  },
  REFRIGERATOR: {
    id: 8,
    //I18N
    name: "Geladeira",
    keywords: ["refrigerator"]
  },
  MICROWAVE: {
    id: 9,
    //I18N
    name: "Micro-ondas",
    keywords: ["microwave"]
  },
  WASHING_MACHINE: {
    id: 10,
    //I18N
    name: "Máquina de lavar",
    keywords: ["washing", "washer", "laundry"]
  },
  PRINTER: {
    id: 11,
    //I18N
    name: "Impressora/Scanner",
    keywords: ["printer", "scanner", "laundry"]
  },
  ALEXA: {
    id: 12,
    //I18N
    name: "Alexa",
    keywords: ["alexa"]
  },
  SWITCH: {
    id: 13,
    //I18N
    name: "Interruptores",
    keywords: ["lighting"]
  },
  POWER_PLUG: {
    id: 14,
    //I18N
    name: "Tomadas",
    keywords: ["plugs"]
  },
  LIGHT: {
    id: 15,
    //I18N
    name: "Lâmpadas",
    keywords: ["light", "hue"]
  },
  CHROMECAST: {
    id: 16,
    //I18N
    name: "Chromecast",
    keywords: ["chromecast"]
  },
  GENERIC_IOT: {
    id: 17,
    //I18N
    name: "Genéricos - IOT",
    keywords: ["generic iot"]
  },
  GENERIC_OTHERS: {
    id: 18,
    //I18N
    name: "Genérico - Outros",
  },
});

module.exports = DeviceTypesEnum;
