const Enum = require("enum");
Enum.register();

const DeviceStatusEnum = new Enum({
  OFFLINE: {
    id: 0,
    //I18N
    name: "Offline",
  },
  ONLINE: {
    id: 1,
    //I18N
    name: "Online",
  },
  ARCHIVED: {
    id: 2,
    //I18N
    name: "Archived",
  },
  ARCHIVED_MANUALLY: {
    id: 3,
    //I18N
    name: "Mannualy Archived",
  },
});

module.exports = DeviceStatusEnum;
