/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from 'react';
import {
    Box,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    Container as MaterialContainer,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import AlertDialog from 'components/AlertDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDeleteUser } from 'services/UserService';
import LoaderContext from 'contexts/LoaderContext';
import { useParams, useNavigate } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { useGetNetworkSnooper, useUpdateNetworkSnooper } from 'services/NetworkService';

export default function UserNetwork({ value, index, user }) {
    const { updateIsLoadingStatus } = useContext(LoaderContext);
    const navigate = useNavigate();

    const [router, setRouter] = useState({});
    const [userData, setUserData] = useState(user);
    const [channels, setChannels] = useState({});
    const [currentInterface, setCurrentInterface] = useState('wlan2');

    const [showPassword, setShowPassword] = useState(false);

    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [snackAlert, setSnackAlert] = useState({
        open: false,
        message: '',
        level: 'success'
    });

    const {
        data: useGetNetworkSnooperData,
        loading: useGetNetworkSnooperLoading,
        error: useGetNetworkSnooperError,
        status: useGetNetworkSnooperStatus,
        request: useGetNetworkSnooperRequest,
    } = useGetNetworkSnooper(user?.id, router?.id, currentInterface);

    const {
        data: useUpdateNetworkSnooperData,
        loading: useUpdateNetworkSnooperLoading,
        status: useUpdateNetworkSnooperStatus,
        request: useUpdateNetworkSnooperRequest,
        source: useUpdateNetworkSnooperSource,
    } = useUpdateNetworkSnooper(user.id, router?.id, currentInterface);

    useEffect(() => {
        if (value !== index) return;

        setUserData(user);
        if (user?.id && router?.id && currentInterface) useGetNetworkSnooperRequest()
    }, [user, router, value, index, currentInterface])

    useEffect(() => {
        if (user?.routers) setRouter(user.routers[0])
    }, [user?.routers])

    useEffect(() => {
        if (!useGetNetworkSnooperStatus) return;
        if (useGetNetworkSnooperStatus != 200 || !useGetNetworkSnooperData?.length) return setSnackAlert({
            open: true,
            message: 'Não foi possível recuperar as informações da rede wi-fi',
            level: 'error'
        });

        setChannels(useGetNetworkSnooperData);
    }, [useGetNetworkSnooperStatus]);

    useEffect(() => {
        if (!useUpdateNetworkSnooperStatus) return;
        if (useUpdateNetworkSnooperStatus === 200 || useUpdateNetworkSnooperStatus === 204) {
            return setSnackAlert({
                open: true,
                message: 'Wi-fi atualizada com sucesso!',
                level: 'success'
            });
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao atualizar a Wi-fi!',
            level: 'error'
        })
    }, [useUpdateNetworkSnooperStatus]);

    useEffect(() => {
        updateIsLoadingStatus(useUpdateNetworkSnooperLoading || useGetNetworkSnooperLoading)
    }, [useUpdateNetworkSnooperLoading, useGetNetworkSnooperLoading])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            <AlertDialog
                isOpen={alertModalOpen}
                setIsOpen={setAlertModalOpen}
                // onConfirm={() => useDeleteUserRequest()}
                message={`Tem certeza que deseja apagar este Wi-fi?\nTodos as informações relacionadas serão removidas e os dados não poderão ser recuperados.`}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackAlert.open} autoHideDuration={3000} onClose={() => setSnackAlert({ ...snackAlert, open: false })}>
                <MuiAlert variant="filled" onClose={() => setSnackAlert({ ...snackAlert, open: false })} severity={snackAlert.level} sx={{ width: '100%' }}>
                    {snackAlert.message || ''}
                </MuiAlert>
            </Snackbar>
            {(value === index && channels?.length) && (
                <Box sx={{ pt: 3 }}>
                    <FormControl fullWidth sx={{ maxWidth: '400px' }}>

                        <FormControl fullWidth margin="normal" color="secondary" focused>
                            <InputLabel id="device-type-label" color="secondary" focused>Interface</InputLabel>
                            <Select
                                labelId="device-type-label"
                                color="secondary"
                                margin="normal"
                                focused
                                fullWidth
                                name="deviceType"
                                label="Tipo do aparelho"
                                value={`${currentInterface}`}
                                onChange={(e) => setCurrentInterface(e?.target?.value || '')}
                            >
                                <MenuItem color="secondary" value={'wlan1'}>Wlan1 - 2GHz</MenuItem>
                                <MenuItem color="secondary" value={'wlan2'}>Wlan2 - 5GHz</MenuItem>

                            </Select>
                        </FormControl>

                        {channels.map(channel => {
                            return <>
                                <Box justifyContent="space-between" alignItems={'center'} mt={'10px'}>
                                    <Typography variant="h4" component="h4">CANAL - {channel?.channel}</Typography>

                                    <Typography variant="h5" component="h5">Redes Concorrentes: {channel?.networkcount}</Typography>

                                    <Typography variant="h5" component="h5">Frequências Concorrentes: {channel?.frequencyCount}</Typography>

                                    <Typography variant="h5" component="h5">Ranking de uso: {channel?.usage?.toFixed(2)}</Typography>

                                    <Button
                                        variant="contained"
                                        disableElevation style={{ fontWeight: 'bolder' }}
                                        sx={{ marginBottom: '15px', marginTop: '5px' }}
                                        onClick={() => useUpdateNetworkSnooperRequest({ frequency: channel?.channel })}
                                    >
                                        ESCOLHER CANAL
                                    </Button>
                                </Box>
                            </>
                        })}
                    </FormControl>
                </Box>
            )}
        </div>
    );
}