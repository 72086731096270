const Enum = require("enum");
Enum.register();

const EventTypesEnum = new Enum({
  CREATE: {
    id: 1,
    //I18N
    name: "criação",
  },
  UPDATE: {
    id: 2,
    //I18N
    name: "edição",
  },
  DELETE: {
    id: 3,
    //I18N
    name: "exclusão",
  },
  BLOCK: {
    id: 4,
    //I18N
    name: "bloqueio",
  },
  RELEASE: {
    id: 5,
    //I18N
    name: "desbloqueio",
  },
});

module.exports = EventTypesEnum;
