import { useApi } from 'services';

export const useGetNetworks = (idUser, idRouter) => useApi(`/network/${idRouter}`, 'GET', { 'x-user-id': idUser });

export const useGetNetwork = (idUser, idRouter, idNetwork) =>
  useApi(`/network/${idNetwork}/${idRouter}`, 'GET', { 'x-user-id': idUser });

export const useCreateNetwork = (idUser, idRouter, name, password) =>
  useApi(`/network/${idRouter}`, 'POST', { 'x-user-id': idUser }, { name, password });

export const useUpdateNetwork = (idUser, idRouter, idNetwork, name, password) =>
  useApi(`/network/${idNetwork}/${idRouter}`, 'PUT', { 'x-user-id': idUser }, { name, password });

export const useUpdateAllNetworks = (idUser, idRouter, name, password) =>
  useApi(`/network/update/all/${idRouter}`, 'PUT', { 'x-user-id': idUser }, { name, password, passwordConfirm: password });

export const useGetNetworkSnooper = (idUser, idRouter, idInterface) =>
useApi(`/network/${idRouter}/snooper/${idInterface}`, 'GET', { 'x-user-id': idUser });

export const useUpdateNetworkSnooper = (idUser, idRouter, idInterface) =>
useApi(`/network/${idRouter}/snooper/${idInterface}`, 'PUT', { 'x-user-id': idUser });

