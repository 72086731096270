/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from 'react';
import { Box, TextField, FormControl, FormControlLabel, Checkbox, Button, Container as MaterialContainer, Input } from '@mui/material';
import AlertDialog from 'components/AlertDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDeleteUser, useUpdateUser } from 'services/UserService';
import LoaderContext from 'contexts/LoaderContext';
import { useParams, useNavigate } from "react-router-dom";
import TextMaskCustom, { CpfMaskInput, PhoneMaskInput } from 'components/TextMaskCustom';

export default function UserDetail({ value, index, user }) {
    const { updateIsLoadingStatus } = useContext(LoaderContext);
    const navigate = useNavigate();

    const [userData, setUserData] = useState(user);
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [snackAlert, setSnackAlert] = useState({
        open: false,
        message: '',
        level: 'success'
    });

    const handleChange = (key, value) => {
        setUserData({ ...userData, [key]: value });
    };

    const {
        data: useUpdateUserData,
        loading: useUpdateUserLoading,
        status: useUpdateUserStatus,
        request: useUpdateUserRequest,
        source: useUpdateUserSource,
    } = useUpdateUser(userData.id, userData);

    const {
        data: useDeleteUserData,
        loading: useDeleteUserLoading,
        status: useDeleteUserStatus,
        request: useDeleteUserRequest,
        source: useDeleteUserSource,
    } = useDeleteUser(userData.id);

    useEffect(() => {
        setUserData(user);
    }, [user])

    useEffect(() => {
        if (!useDeleteUserStatus) return;
        if (useDeleteUserStatus === 200 || useDeleteUserStatus === 204) {
            setSnackAlert({
                open: true,
                message: 'Usuário removida com sucesso!',
                level: 'success'
            });
            return navigate(-1)
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao remover a usuário!',
            level: 'error'
        })
    }, [useDeleteUserStatus]);

    useEffect(() => {
        if (!useUpdateUserStatus) return;
        if (useUpdateUserStatus === 200 || useUpdateUserStatus === 204) {
            return setSnackAlert({
                open: true,
                message: 'Usuário atualizada com sucesso!',
                level: 'success'
            });
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao atualizar a usuário!',
            level: 'error'
        })
    }, [useUpdateUserStatus]);

    useEffect(() => {
        updateIsLoadingStatus(useUpdateUserLoading || useDeleteUserLoading)
    }, [useUpdateUserLoading, useDeleteUserLoading])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <AlertDialog
                        isOpen={alertModalOpen}
                        setIsOpen={setAlertModalOpen}
                        onConfirm={() => useDeleteUserRequest()}
                        message={`Tem certeza que deseja apagar este usuário?\nTodos as informações relacionadas serão removidas e os dados não poderão ser recuperados.`}
                    />
                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={snackAlert.open} autoHideDuration={3000} onClose={() => setSnackAlert({ ...snackAlert, open: false })}>
                        <MuiAlert variant="filled" onClose={() => setSnackAlert({ ...snackAlert, open: false })} severity={snackAlert.level} sx={{ width: '100%' }}>
                            {snackAlert.message || ''}
                        </MuiAlert>
                    </Snackbar>
                    <FormControl fullWidth sx={{ maxWidth: '400px' }}>
                        <TextField
                            label="Nome"
                            color="secondary"
                            margin="normal"
                            focused
                            value={userData?.name}
                            fullWidth
                            onChange={(e) => handleChange('name', e?.target?.value || '')}
                        />
                        <TextField
                            label="E-mail"
                            color="secondary"
                            margin="normal"
                            focused
                            value={userData?.email}
                            fullWidth
                            onChange={(e) => handleChange('email', e?.target?.value || '')}
                        />
                        <TextField
                            label="Telefone"
                            color="secondary"
                            margin="normal"
                            focused
                            value={userData?.phone}
                            fullWidth
                            onChange={(e) => handleChange('phone', e?.target?.value || '')}
                            InputProps={{
                                inputComponent: PhoneMaskInput
                            }}
                        />
                        <TextField
                            label="CPF"
                            color="secondary"
                            margin="normal"
                            focused
                            value={userData?.cpf}
                            fullWidth
                            onChange={(e) => handleChange('cpf', e?.target?.value || '')}
                            InputProps={{
                                inputComponent: CpfMaskInput
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                onChange={e => handleChange('isAdmin', e?.target?.checked)}
                                checked={!!userData.isAdmin} />}
                            label="Admin"
                        />

                        <FormControlLabel
                            control={<Checkbox
                                onChange={e => handleChange('isSubscriber', e?.target?.checked)}
                                checked={!!userData.isSubscriber} />}
                            label="Assinante"
                        />
                        
                        <FormControl fullWidth>
                            <Button
                                variant="contained"
                                disableElevation style={{ fontWeight: 'bolder' }}
                                sx={{ marginTop: '40px' }}
                                onClick={() => useUpdateUserRequest(userData)}
                            >
                                SALVAR
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                disableElevation style={{ fontWeight: 'bolder' }}
                                sx={{ marginTop: '10px' }}
                                onClick={() => setAlertModalOpen(true)}
                            >
                                REMOVER
                            </Button>
                        </FormControl>

                    </FormControl>
                </Box>
            )}
        </div>
    );
}