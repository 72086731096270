const Enum = require("enum");
Enum.register();

const UserRouterTypeEnum = new Enum({
  ADMINISTRATOR: {
    id: 0,
    //I18N
    name: "Administrador",
  },
  GUEST: {
    id: 1,
    //I18N
    name: "Convidado",
  },
});

module.exports = UserRouterTypeEnum;
