const Enum = require("enum");
Enum.register();

const RoomTypesEnum = new Enum({
  LIVING_ROOM: {
    id: 1,
    //I18N
    name: "Sala de Estar",
  },
  DINING_ROOM: {
    id: 2,
    //I18N
    name: "Sala de Jantar",
  },
  KITCHEN: {
    id: 3,
    //I18N
    name: "Cozinha",
  },
  BEDROOM: {
    id: 4,
    //I18N
    name: "Quarto",
  },
  OFFICE: {
    id: 5,
    //I18N
    name: "Escritório",
  },
  RESTROOM: {
    id: 6,
    //I18N
    name: "Banheiro social",
  },
  BATHROOM: {
    id: 7,
    //I18N
    name: "Banheiro",
  },
  GARAGE: {
    id: 8,
    //I18N
    name: "Garagem",
  },
});

module.exports = RoomTypesEnum;
