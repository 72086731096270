import { useApi } from "services";

export const useLogin = (email, password) =>
  useApi(`/user/auth`, "POST", {}, { email, password });

export const useGetUsers = () => useApi(`/user/admin/all`, "GET", {}, {});

export const useGetUsersWithFilter = (userType) =>
  useApi(`/user/admin/all/${userType}`, "GET", {}, { userType });

export const useGetUsersWithFilterPost = (
  userType,
  userName,
  routerStatus,
  checkedADM,
  serialNumber, 
  wifiName
) =>
  useApi(
    `/user/admin/allPost`,
    "POST",
    {},
    { userType, userName, routerStatus, checkedADM, serialNumber, wifiName }
  );

export const useGetUser = (idUser) =>
  useApi(`/user/admin/${idUser}`, "GET", {}, {});

export const useCreateUser = (obj) => useApi("/user", "POST", {}, obj);

export const useUpdateUser = (idUser, obj) =>
  useApi(`/user/admin/${idUser}`, "PUT", { "x-user-id": idUser }, obj);

export const useDeleteUser = (idUser) =>
  useApi(`/user/${idUser}`, "DELETE", { "x-user-id": idUser }, {});

export const useResetPasswordRequest = (obj) =>
  useApi("/user/resetPasswordRequest", "POST", {}, obj, 10000000);

export const useResetPassword = (obj) =>
  useApi("/user/resetPassword", "PUT", {}, obj);
