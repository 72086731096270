const Enum = require("enum");
Enum.register();

const EventContainersEnum = new Enum({
  HOME: {
    id: 1,
    //I18N
    name: "Home",
  },
  DEVICES: {
    id: 2,
    //I18N
    name: "Aparelhos",
  },
  DEVICE_DETAILS: {
    id: 3,
    //I18N
    name: "AparelhosDetalhe",
  },
  PERSON: {
    id: 4,
    //I18N
    name: "Pessoas",
  },
  PERSON_DETAILS: {
    id: 5,
    //I18N
    name: "PessoasDetalhe",
  },
  ROUTINE: {
    id: 6,
    //I18N
    name: "Rotinas",
  },
  ROUTINE_DETAILS: {
    id: 7,
    //I18N
    name: "RotinasDetalhe",
  },
});

module.exports = EventContainersEnum;
