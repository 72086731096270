/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from 'react';
import Container from "components/Container";

import {
    Box,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    Container as MaterialContainer,
    Typography,
    MenuItem,
    Select,
    InputLabel,
    Switch,
    Grid,
    FormGroup,
    FormLabel,
    Radio
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useGetUser } from 'services/UserService';
import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import { useAllowPerson, useBlockPerson, useCreatePerson, useDeletePerson, useUpdatePerson } from 'services/PeopleService';
import LoaderContext from 'contexts/LoaderContext';
import AlertDialog from 'components/AlertDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { IconArrowLeft as IconArrowBack } from '@tabler/icons';

export default function UserPerson() {
    const { updateIsLoadingStatus } = useContext(LoaderContext);
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [person, setPerson] = useState({});
    const [router, setRouter] = useState({});
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [snackAlert, setSnackAlert] = useState({
        open: false,
        message: '',
        level: 'success'
    });

    let { idUser, idPerson } = useParams();

    const handleChange = (key, value) => {
        setPerson({ ...person, [key]: value });
    };

    const {
        data: useGetUserData,
        loading: useGetUserLoading,
        error: useGetUserError,
        status: useGetUserStatus,
        request: useGetUserRequest,
    } = useGetUser(idUser);

    const {
        data: useUpdatePersonData,
        loading: useUpdatePersonLoading,
        status: useUpdatePersonStatus,
        request: useUpdatePersonRequest,
        source: useUpdatePersonSource,
    } = useUpdatePerson(idUser, router?.id, person?.id, person);

    const {
        data: useCreatePersonData,
        loading: useCreatePersonLoading,
        status: useCreatePersonStatus,
        request: useCreatePersonRequest,
        source: useCreatePersonSource,
    } = useCreatePerson(idUser, router?.id, person);

    const {
        data: useBlockPersonData,
        loading: useBlockPersonLoading,
        status: useBlockPersonStatus,
        request: useBlockPersonRequest,
        source: useBlockPersonSource,
    } = useBlockPerson(idUser, router?.id, person?.id);

    const {
        data: useAllowPersonData,
        loading: useAllowPersonLoading,
        status: useAllowPersonStatus,
        request: useAllowPersonRequest,
        source: useAllowPersonSource,
    } = useAllowPerson(idUser, router?.id, person?.id);

    const {
        data: useDeletePersonData,
        loading: useDeletePersonLoading,
        status: useDeletePersonStatus,
        request: useDeletePersonRequest,
        source: useDeletePersonSource,
    } = useDeletePerson(idUser, router?.id, person?.id, true);

    useEffect(() => {
        useGetUserRequest()
    }, [])

    useEffect(() => {
        if (useGetUserStatus !== 200) return;
        setUser(useGetUserData);
    }, [useGetUserStatus]);

    useEffect(() => {
        if (user?.routers) setRouter(user.routers[0])
    }, [user?.routers])

    useEffect(() => {
        if (!idPerson) return;
        const person = router?.people?.find(v => v.id === +idPerson);
        setPerson(person);
    }, [router]);


    useEffect(() => {
        if (!useDeletePersonStatus) return;
        if (useDeletePersonStatus === 200 || useDeletePersonStatus === 204) {
            setSnackAlert({
                open: true,
                message: 'Pessoa removida com sucesso!',
                level: 'success'
            });
            return navigate(-1)
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao remover a pessoa!',
            level: 'error'
        })
    }, [useDeletePersonStatus]);

    useEffect(() => {
        if (!useUpdatePersonStatus && !useCreatePersonStatus) return;
        if (useUpdatePersonStatus === 200 ||
            useUpdatePersonStatus === 204 ||
            useCreatePersonStatus === 200 ||
            useCreatePersonStatus === 204
        ) {
            setSnackAlert({
                open: true,
                message: 'Pessoa salva com sucesso!',
                level: 'success'
            });

            if (useCreatePersonData?.id) {
                useGetUserRequest();
                return navigate(`/users/${idUser}/person/${useCreatePersonData.id}`)
            }

            return;
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao salvar a pessoa!',
            level: 'error'
        })
    }, [useUpdatePersonStatus, useCreatePersonStatus]);

    useEffect(() => {
        if (!useAllowPersonStatus) return;
        if (useAllowPersonStatus === 200 || useAllowPersonStatus === 204) {
            return setSnackAlert({
                open: true,
                message: 'Pessoa desbloqueada com sucesso!',
                level: 'success'
            });
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao desbloquear a pessoa!',
            level: 'error'
        })
    }, [useAllowPersonStatus]);

    useEffect(() => {
        if (!useBlockPersonStatus) return;
        if (useBlockPersonStatus === 200 || useBlockPersonStatus === 204) {
            return setSnackAlert({
                open: true,
                message: 'Pessoa bloqueada com sucesso!',
                level: 'success'
            });
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao bloquer a pessoa!',
            level: 'error'
        })
    }, [useBlockPersonStatus]);

    useEffect(() => {
        updateIsLoadingStatus(useCreatePersonLoading || useUpdatePersonLoading || useGetUserLoading || useBlockPersonLoading || useAllowPersonLoading || useDeletePersonLoading)
    }, [useCreatePersonLoading, useUpdatePersonLoading, useGetUserLoading, useBlockPersonLoading, useAllowPersonLoading, useDeletePersonLoading])

    return (
        <Container>
            <AlertDialog
                isOpen={alertModalOpen}
                setIsOpen={setAlertModalOpen}
                onConfirm={() => useDeletePersonRequest()}
                message={`Tem certeza que deseja apagar esta pessoa?\nTodos as informações relacionadas serão removidas e os dados não poderão ser recuperados.`}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackAlert.open} autoHideDuration={3000} onClose={() => setSnackAlert({ ...snackAlert, open: false })}>
                <MuiAlert variant="filled" onClose={() => setSnackAlert({ ...snackAlert, open: false })} severity={snackAlert.level} sx={{ width: '100%' }}>
                    {snackAlert.message || ''}
                </MuiAlert>
            </Snackbar>

            <Typography variant="h1" component="h3" style={{ marginBottom: 10 }}>
                {user?.name}
            </Typography>

            <Grid container spacing={2}>
                <Grid xs={12} sm={6} sx={{ padding: '10px' }}>
                    {idPerson && <FormControlLabel
                        control={<Switch
                            checked={person?.isBlocked}
                            color="secondary"
                            margin="normal"
                            name="isBlocked"
                        />}
                        name="isBlocked"
                        label={person?.isBlocked ? 'Desloquear' : 'Bloquear'}
                        onChange={(e) => {
                            handleChange('isBlocked', e?.target?.checked)
                            !e.target.checked ? useAllowPersonRequest() : useBlockPersonRequest();
                        }}
                    />}
                    <FormControl fullWidth>
                        <TextField
                            label="Nome"
                            color="secondary"
                            margin="normal"
                            focused value={person?.name}
                            fullWidth
                            onChange={(e) => handleChange('name', e?.target?.value || '')}
                        />
                    </FormControl>

                    <FormControl fullWidth focused color="secondary" margin="normal">
                        <DatePicker
                            value={person?.birthdayDate ? moment(person?.birthdayDate) : null}
                            label="Data de Nascimento"
                            color="secondary"
                            margin="normal"
                            focused
                            onChange={(value) => handleChange('birthdayDate', value || '')}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <Button
                            variant="contained"
                            disableElevation style={{ fontWeight: 'bolder' }}
                            sx={{ marginTop: '40px' }}
                            onClick={() => idPerson ? useUpdatePersonRequest(person) : useCreatePersonRequest(person)}
                        >
                            SALVAR
                        </Button>
                        {idPerson && <Button
                            variant="contained"
                            color="error"
                            disableElevation style={{ fontWeight: 'bolder' }}
                            sx={{ marginTop: '10px' }}
                            onClick={() => setAlertModalOpen(true)}
                        >
                            REMOVER
                        </Button>}
                    </FormControl>

                    <Typography variant="h3" component="h4" style={{ margin: '50px 0' }}>
                        <Link style={{ display: 'flex' }} to={`/users/${user?.id}?tabIndex=4`}>
                            <IconArrowBack /> Voltar
                        </Link>
                    </Typography>
                </Grid>

                {idPerson && <Grid xs={12} sm={6} sx={{ padding: '10px' }}>
                    <Box justifyContent="space-between" alignItems={'center'} mt={'10px'}>
                        <Typography variant="h4" component="h4">Consumo</Typography>
                        <Typography>{person?.todayUsageInMinutes} minutos</Typography>
                    </Box>

                    <Box justifyContent="space-between" alignItems={'center'} mt={'10px'}>
                        <Typography variant="h4" component="h4">Dispositivos</Typography>
                        {person?.devices?.map(device => <Typography>{device?.name}</Typography>)}
                    </Box>
                </Grid>}
            </Grid>

        </Container>
    );
}