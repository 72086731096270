import { useApi } from 'services';

export const useGetDevices = (idUser, idRouter, page = 1, limit) =>
  useApi(`/device/${idRouter}?page=${page}${limit ? '&limit=' + limit : ''}`, 'GET', { 'x-user-id': idUser });

export const useGetDevice = (idUser, idRouter, macAddress) =>
  useApi(`/device/${idRouter}/${macAddress}`, 'GET', { 'x-user-id': idUser });

export const useUpdateDevice = (idUser, idRouter, macAddress, data) =>
  useApi(`/device/${idRouter}/${macAddress}`, 'PUT', { 'x-user-id': idUser }, { ...data });

export const useDeleteDevice = (idUser, idRouter, macAddress, canDeleteRoutines) =>
  useApi(`/device/${idRouter}/${macAddress}`, 'DELETE', { 'x-user-id': idUser }, { canDeleteRoutines });

export const useBlockDevice = (idUser, idRouter, macAddress) =>
  useApi(`/device/block/${idRouter}/${macAddress}`, 'PUT', { 'x-user-id': idUser }, {});

export const useAllowDevice = (idUser, idRouter, macAddress) =>
  useApi(`/device/allow/${idRouter}/${macAddress}`, 'PUT', { 'x-user-id': idUser }, {});

export const useGetDeviceTimeUsageDay = (idUser, idRouter, macAddress, day) =>
  useApi(`/device/time-usage/day/${idRouter}/${macAddress}?day=${day}`, 'GET', { 'x-user-id': idUser });

export const useGetDeviceTimeUsageWeek = (idUser, idRouter, macAddress, week, year) =>
  useApi(`/device/time-usage/week/${idRouter}/${macAddress}?week=${week}&year=${year}`, 'GET', { 'x-user-id': idUser });

export const useRestoreDevice = (idUser, idRouter, macAddress) =>
  useApi(`/device/restore/${idRouter}/${macAddress}`, 'PUT', { 'x-user-id': idUser }, {});

export const useGetDeviceTimeUsageMonth = (idUser, idRouter, macAddress, month, year) =>
  useApi(
    `/device/time-usage/month/${idRouter}/${macAddress}?month=${month}&year=${year}`,
    'GET',
    { 'x-user-id': idUser },
  );

export const useGetDeviceTimeUsageYear = (idUser, idRouter, macAddress, year) =>
  useApi(`/device/time-usage/year/${idRouter}/${macAddress}?year=${year}`, 'GET', { 'x-user-id': idUser });