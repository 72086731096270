const Enum = require("enum");
Enum.register();

const RoutineTypeEnum = new Enum({
  DISCONNECT_PER_TIME: {
    id: 0,
    name: "disconnectPerTime",
    //I18N
    value: "Interromper conexão dos aparelhos por período"
  },
  DISCONNECT_PER_TIME_INTERMITTENT: {
    id: 1,
    name: "disconnectPerTimeIntermittent",
    //I18N
    value: "Interromper conexão de forma intermitente"
  },
  DISCONNECT_PER_TIME_USAGE: {
    id: 2,
    name: "disconnectPerTimeUsage",
    //I18N
    value: "Tempo de uso"
  },
  DISCONNECT_PER_DOMAIN: {
    id: 3,
    name: "disconnectPerDomain",
    //I18N
    value: "Interromper conexão dos aparelhos por site ou app"
  },
  DISCONNECT_PER_DOMAIN_INTERMITTENT: {
    id: 4,
    name: "disconnectPerDomainIntermittent",
    //I18N
    value: "Interromper conexão dos aparelhos por site ou app de forma intermitente"
  }
});

module.exports = RoutineTypeEnum;
