/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from 'react';
import Container from "components/Container";

import {
    Box,
    TextField,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    Container as MaterialContainer,
    Typography,
    MenuItem,
    Select,
    InputLabel,
    Switch,
    Grid,
    FormGroup,
    FormLabel,
    Radio
} from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { useGetUser } from 'services/UserService';
import { useParams, useNavigate } from "react-router-dom";
import { RoutineTypeEnum } from 'codewall-common/enums';
import moment from 'moment';
import AlertDialog from 'components/AlertDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useCreateRoutine, useDeleteRoutine, useUpdateRoutine } from 'services/RoutineService';
import LoaderContext from 'contexts/LoaderContext';
import { Link } from 'react-router-dom';
import { IconArrowLeft as IconArrowBack } from '@tabler/icons';

export default function UserRoutine() {
    const { updateIsLoadingStatus } = useContext(LoaderContext);
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [routine, setRoutine] = useState({});
    const [router, setRouter] = useState({});
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [snackAlert, setSnackAlert] = useState({
        open: false,
        message: '',
        level: 'success'
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [value, setValue] = useState(0);
    let { idUser, idRoutine } = useParams();

    function timeToMinutes(time) {
        let timeSplitted = time.split(":");
        return parseInt(timeSplitted[0]) * 60 + parseInt(timeSplitted[1]);
    }

    function minuteToTime(minute) {
        return `${Math.trunc(minute / 60).toLocaleString(undefined, {
            minimumIntegerDigits: 2,
        })}:${Math.trunc(Math.abs(minute) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}`;
    }

    const handleChange = (key, value) => {
        let routineAux = { ...routine };
        if (['room', 'device', 'people'].includes(key)) {
            routineAux.room = null;
            routineAux.device = null;
            routineAux.people = null;
        }

        if ('disconnectTimeUsage' === key) {
            routineAux.disconnectEndTime = null;
            routineAux.disconnectStartTime = null;
        }

        if ('disconnectStartTime' === key || 'disconnectEndTime' === key) {
            routineAux.disconnectTimeUsage = null;
        }

        setRoutine({ ...routineAux, [key]: value });
    };

    const handleChangeLists = (key, value, checked) => {
        let values = routine[key] || [];

        if (!checked) values = values.filter(v => v !== value);
        if (checked) values.push(value);

        setRoutine({ ...routine, [key]: values });
    };

    const routineTypes = {
        0: 'Interromper conexão dos aparelhos por período',
        1: 'Interromper conexão de forma intermitente',
        2: 'Tempo de uso'
    }

    const routineTypesList = RoutineTypeEnum.enums.map((res) => ({ ...res.value, name: routineTypes[res.value.id] }));

    const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    const {
        data: useGetUserData,
        loading: useGetUserLoading,
        error: useGetUserError,
        status: useGetUserStatus,
        request: useGetUserRequest,
    } = useGetUser(idUser);

    const {
        data: useUpdateRoutineData,
        loading: useUpdateRoutineLoading,
        status: useUpdateRoutineStatus,
        request: useUpdateRoutineRequest,
        source: useUpdateRoutineSource,
    } = useUpdateRoutine(idUser, router?.id, routine?.id, routine);

    const {
        data: useCreateRoutineData,
        loading: useCreateRoutineLoading,
        status: useCreateRoutineStatus,
        request: useCreateRoutineRequest,
        source: useCreateRoutineSource,
    } = useCreateRoutine(idUser, router?.id, routine);

    const {
        data: useDeleteRoutineData,
        loading: useDeleteRoutineLoading,
        status: useDeleteRoutineStatus,
        request: useDeleteRoutineRequest,
        source: useDeleteRoutineSource,
    } = useDeleteRoutine(idUser, router?.id, routine?.id);

    useEffect(() => {
        useGetUserRequest(null, { page: currentPage })
    }, [currentPage])

    useEffect(() => {
        if (useGetUserStatus !== 200) return;
        setUser(useGetUserData);
    }, [useGetUserStatus]);

    useEffect(() => {
        if (user?.routers) setRouter(user.routers[0])
    }, [user?.routers])

    useEffect(() => {
        if (!idRoutine) return;
        const routine = router?.routines?.find(v => v.id === +idRoutine);
        setRoutine(routine);
    }, [router]);

    useEffect(() => {
        if (!useDeleteRoutineStatus) return;
        if (useDeleteRoutineStatus === 200 || useDeleteRoutineStatus === 204) {
            setSnackAlert({
                open: true,
                message: 'Rotina removida com sucesso!',
                level: 'success'
            });
            return navigate(-1)
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao remover a rotina!',
            level: 'error'
        })
    }, [useDeleteRoutineStatus]);

    useEffect(() => {
        if (!useUpdateRoutineStatus && !useCreateRoutineStatus) return;
        if (
            useUpdateRoutineStatus === 200 ||
            useUpdateRoutineStatus === 204 ||
            useCreateRoutineStatus === 200 ||
            useCreateRoutineStatus === 204
        ) {
            setSnackAlert({
                open: true,
                message: 'Rotina salva com sucesso!',
                level: 'success'
            });

            if (useCreateRoutineData?.id) {
                useGetUserRequest();
                return navigate(`/users/${idUser}/routine/${useCreateRoutineData.id}`)
            }

            return;
        }

        setSnackAlert({
            open: true,
            message: 'Falha ao salvar a rotina!',
            level: 'error'
        })
    }, [useUpdateRoutineStatus, useCreateRoutineStatus]);

    const formatDateTimeToDatePicker = (stringHour) => {
        if (Number.isInteger(stringHour)) stringHour = minuteToTime(stringHour);

        const date = routine?.nextInteractionAt ? new Date(routine.nextInteractionAt) : new Date();
        const splitHour = stringHour ? stringHour.split(':') : [];

        if (splitHour?.length) {
            date.setHours(splitHour[0]);
            date.setMinutes(splitHour[1]);
        }

        return moment(date);
    };

    useEffect(() => {
        updateIsLoadingStatus(useUpdateRoutineLoading || useCreateRoutineLoading || useGetUserLoading || useDeleteRoutineLoading)
    }, [useUpdateRoutineLoading, useCreateRoutineLoading, useGetUserLoading, useDeleteRoutineLoading])

    return (
        <Container>
            <AlertDialog
                isOpen={alertModalOpen}
                setIsOpen={setAlertModalOpen}
                onConfirm={() => useDeleteRoutineRequest()}
                message={`Tem certeza que deseja apagar esta rotina?\nTodos as informações relacionadas serão removidas e os dados não poderão ser recuperados.`}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackAlert.open} autoHideDuration={3000} onClose={() => setSnackAlert({ ...snackAlert, open: false })}>
                <MuiAlert variant="filled" onClose={() => setSnackAlert({ ...snackAlert, open: false })} severity={snackAlert.level} sx={{ width: '100%' }}>
                    {snackAlert.message || ''}
                </MuiAlert>
            </Snackbar>

            <Typography variant="h1" component="h3" style={{ marginBottom: 10 }}>
                {user?.name}
            </Typography>
            <Typography variant="h3" component="h4" style={{ marginBottom: 10 }}>
                {idRoutine ? `Rotina: ${routine?.name}` : 'Criar Rotina'}
            </Typography>

            <Grid container spacing={2}>
                <Grid xs={12} sm={6} sx={{ padding: '10px' }}>

                    {!idRoutine &&
                        <FormControl fullWidth margin="normal" color="secondary" focused>
                            <InputLabel id="device-type-label" color="secondary" focused>Tipo de rotina</InputLabel>
                            <Select
                                labelId="device-type-label"
                                color="secondary"
                                margin="normal"
                                focused
                                fullWidth
                                name="type"
                                label="Tipo do Rotina"
                                value={`${routine?.type}`}
                                onChange={(e) => handleChange('type', e?.target?.value)}
                            >
                                {routineTypesList.map(routine => <MenuItem color="secondary" value={routine?.id}>{routine?.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    }

                    <FormControl fullWidth>
                        <TextField
                            label="Nome"
                            color="secondary"
                            margin="normal"
                            focused
                            value={routine?.name}
                            fullWidth
                            onChange={(e) => handleChange('name', e?.target?.value || '')}
                        />
                    </FormControl>

                    {!idRoutine &&
                        <Box justifyContent="space-between" alignItems={'center'} mt={'50px'}>
                            <Typography variant="h4" component="h4">Dispositivo, pessoa ou cômodo</Typography>
                        </Box>
                    }

                    {!idRoutine && <FormControl fullWidth margin="normal" color="secondary" focused>
                        <InputLabel id="device-type-label" color="secondary" focused>Dispositivo</InputLabel>
                        <Select
                            labelId="device-type-label"
                            color="secondary"
                            margin="normal"
                            focused
                            fullWidth
                            name="type"
                            label="Dispositivo"
                            value={`${routine?.device}`}
                            onChange={(e) => handleChange('device', e?.target?.value)}
                        >
                            {router?.devices?.filter(device => device.registeredAt).map(device => <MenuItem color="secondary" value={device?.id}>{device?.name}</MenuItem>)}
                        </Select>
                    </FormControl>}

                    {!idRoutine && <FormControl fullWidth margin="normal" color="secondary" focused>
                        <InputLabel id="device-type-label" color="secondary" focused>Pessoa</InputLabel>
                        <Select
                            labelId="device-type-label"
                            color="secondary"
                            margin="normal"
                            focused
                            fullWidth
                            name="type"
                            label="Pessoa"
                            value={`${routine?.people}`}
                            onChange={(e) => handleChange('people', e?.target?.value)}
                        >
                            {router?.people?.map(people => <MenuItem color="secondary" value={people?.id}>{people?.name}</MenuItem>)}
                        </Select>
                    </FormControl>}

                    {!idRoutine && <FormControl fullWidth margin="normal" color="secondary" focused>
                        <InputLabel id="device-type-label" color="secondary" focused>Cômodo</InputLabel>
                        <Select
                            labelId="device-type-label"
                            color="secondary"
                            margin="normal"
                            focused
                            fullWidth
                            name="type"
                            label="Cômodo"
                            value={`${routine?.room}`}
                            onChange={(e) => handleChange('room', e?.target?.value)}
                        >
                            {router?.rooms?.map(room => <MenuItem color="secondary" value={room?.id}>{room?.name}</MenuItem>)}
                        </Select>
                    </FormControl>}

                    <Box justifyContent="space-between" alignItems={'center'} mt={'50px'}>
                        <Typography variant="h4" component="h4">Dia da semana e tempo de uso</Typography>
                    </Box>

                    <Grid container>
                        {(routine?.type != 2) && <>
                            <Grid xs={12} sm={6} sx={{ paddingRight: '10px' }}>
                                <FormControl fullWidth focused color="secondary" margin="normal">
                                    <MobileTimePicker
                                        value={routine?.disconnectStartTime ? formatDateTimeToDatePicker(routine?.disconnectStartTime) : null}
                                        label="Horário inicial"
                                        color="secondary"
                                        margin="normal"
                                        focused
                                        onChange={(value) => handleChange('disconnectStartTime', value ? `${String(value.get('hour')).padStart(2, '0')}:${String(value.get('minutes')).padStart(2, '0')}` : '')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <FormControl fullWidth focused color="secondary" margin="normal">
                                    <MobileTimePicker
                                        value={routine?.disconnectEndTime ? formatDateTimeToDatePicker(routine?.disconnectEndTime) : null}
                                        label="Horário final"
                                        color="secondary"
                                        margin="normal"
                                        focused
                                        onChange={(value) => handleChange('disconnectEndTime', value ? `${String(value.get('hour')).padStart(2, '0')}:${String(value.get('minutes')).padStart(2, '0')}` : '')}
                                    />
                                </FormControl>
                            </Grid>
                        </>}

                        {routine?.type == 2 && <Grid xs={12} sm={6} sx={{ paddingRight: '10px' }}>
                            <FormControl fullWidth focused color="secondary" margin="normal">
                                <MobileTimePicker
                                    value={routine?.disconnectTimeUsage ? formatDateTimeToDatePicker(routine?.disconnectTimeUsage) : null}
                                    label="Tempo Permitido"
                                    color="secondary"
                                    margin="normal"
                                    focused
                                    onChange={(value) => handleChange('disconnectTimeUsage', value ? `${String(value.get('hour')).padStart(2, '0')}:${String(value.get('minutes')).padStart(2, '0')}` : '')}
                                />
                            </FormControl>
                        </Grid>}
                    </Grid>

                    <FormControl fullWidth margin="normal" color="secondary" focused>
                        <FormLabel component="legend">Dias da semana</FormLabel>
                        <FormGroup>
                            {weekDays?.map((day, index) => <FormControlLabel
                                checked={!!routine?.disconnectDaysOfWeek?.includes(index)}
                                control={<Checkbox
                                    onChange={e => handleChangeLists('disconnectDaysOfWeek', index, e?.target?.checked)}
                                    name="day" />
                                }
                                label={day}
                            />)}
                        </FormGroup>
                    </FormControl>

                    <FormControl fullWidth>
                        <Button
                            variant="contained"
                            disableElevation style={{ fontWeight: 'bolder' }}
                            sx={{ marginTop: '40px' }}
                            onClick={() =>
                                !idRoutine ?
                                    useCreateRoutineRequest({
                                        ...routine,
                                        device: routine.device || undefined,
                                        people: routine.people || undefined,
                                        room: routine.room || undefined,
                                        disconnectTimeUsage: routine.disconnectTimeUsage ? timeToMinutes(routine.disconnectTimeUsage) : undefined
                                    }) :
                                    useUpdateRoutineRequest({
                                        ...routine,
                                        device: routine.device?.id || undefined,
                                        people: routine.people?.id || undefined,
                                        room: routine.room?.id || undefined,
                                        disconnectTimeUsage: routine.disconnectTimeUsage ? timeToMinutes(routine.disconnectTimeUsage) : undefined
                                    })}
                        >
                            SALVAR
                        </Button>
                        {idRoutine && <Button
                            variant="contained"
                            color="error"
                            disableElevation style={{ fontWeight: 'bolder' }}
                            sx={{ marginTop: '10px' }}
                            onClick={() => setAlertModalOpen(true)}
                        >
                            REMOVER
                        </Button>}
                    </FormControl>

                    <Typography variant="h3" component="h4" style={{ margin: '50px 0' }}>
                        <Link style={{ display: 'flex' }} to={`/users/${user?.id}?tabIndex=3`}>
                            <IconArrowBack /> Voltar
                        </Link>
                    </Typography>
                </Grid>

                {idRoutine && <Grid xs={12} sm={6} sx={{ padding: '10px' }}>
                    <Box justifyContent="space-between" alignItems={'center'} mt={'10px'}>
                        <Typography variant="h4" component="h4">Tipo de Rotina</Typography>
                        <Typography>{routineTypes[routine?.type]}</Typography>
                    </Box>

                    {routine?.device && <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Dispositivo</Typography>
                        <Typography>{routine?.device?.name}</Typography>
                    </Box>}

                    {routine?.room && <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Comodo</Typography>
                        <Typography>{routine?.room?.name}</Typography>
                    </Box>}

                    {routine?.people && <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Pessoa</Typography>
                        <Typography>{routine?.people?.name}</Typography>
                    </Box>}

                    <Box justifyContent="space-between" alignItems={'center'} mt={'10px'}>
                        <Typography variant="h4" component="h4">Bloqueado</Typography>
                        <Typography>{routine?.currentlyBlocked ? 'SIM' : 'NÃO'}</Typography>
                    </Box>

                    <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Progresso</Typography>
                        <Typography>{routine?.progress || 0}%</Typography>
                    </Box>

                    {routine?.type === 1 && <Box justifyContent="space-between" alignItems={'center'} mt={'15px'}>
                        <Typography variant="h4" component="h4">Prox. Execução (Intermitente)</Typography>
                        <Typography>{routine?.nextInteractionAt ? moment(routine?.nextInteractionAt).format('DD/MM/YYYY, HH:mm') : '-'}</Typography>
                    </Box>}
                </Grid>}
            </Grid>
        </Container>
    );
}