const CommandSpoolCommandTypeEnum = require('./src/enums/CommandSpoolCommandTypeEnum');
const DeviceStatusEnum = require('./src/enums/DeviceStatusEnum');
const PeopleStatusEnum = require('./src/enums/PeopleStatusEnum');
const PortForwardingStatusEnum = require('./src/enums/PortForwardingStatusEnum');
const RoomStatusEnum = require('./src/enums/RoomStatusEnum');
const RouterStatusEnum = require('./src/enums/RouterStatusEnum');
const UserRouterStatusEnum = require('./src/enums/UserRouterStatusEnum');
const UserRouterTypeEnum = require('./src/enums/UserRouterTypeEnum');
const UserStatusEnum = require('./src/enums/UserStatusEnum');
const DeviceTypesEnum = require('./src/enums/DeviceTypesEnum');
const RoomTypesEnum = require('./src/enums/RoomTypesEnum');
const WeekDaysEnum = require('./src/enums/WeekDaysEnum');
const RoutineTypeEnum = require('./src/enums/RoutineTypeEnum');
const EventContainersEnum = require('./src/enums/EventContainersEnum');
const EventTypesEnum = require('./src/enums/EventTypesEnum');
const UserFilterEnum = require('./src/enums/UserFilterEnum');
const DomainsEnum = require('./src/enums/DomainsEnum');

module.exports = {
    CommandSpoolCommandTypeEnum,
    DeviceStatusEnum,
    PeopleStatusEnum,
    PortForwardingStatusEnum,
    RoomStatusEnum,
    RouterStatusEnum,
    UserRouterStatusEnum,
    UserRouterTypeEnum,
    UserStatusEnum,
    DeviceTypesEnum,
    RoomTypesEnum,
    WeekDaysEnum,
    RoutineTypeEnum,
    EventContainersEnum,
    EventTypesEnum,
    UserFilterEnum,
  	DomainsEnum,
};
