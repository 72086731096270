import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import Logo from 'assets/images/logo.svg';

const LogoSection = () => {
    return (
        <ButtonBase disableRipple component={Link}>
            <img src={Logo} alt="Berry" width="100" />
        </ButtonBase>
    );
};

export default LogoSection;
